import dateUtils from '@/utils/dateUtils'
import { doQuery } from "@/api/core"
import { mapGetters } from 'vuex'
import CmyPage from '@/components/cmy-page/cmy-page.vue'
import util from '@/utils/cmyUtils.js'

export default {
    name: "lesson",
    components: {
        CmyPage
    },
    data() {
        return {

            //new：知识目录
            catalog_data: [],
            catalog: [],
            catalog_selected:'0',

            recommend_lessons: [],
            searchText: undefined,
            typeFilter:[
                {
                    name: 'kdbtype', title:'业务类型', show: false, select: 0,
                    typeList: []
                },
                {
                    name: 'freetype', title: '媒体类型',  show: false, select: 0,
                    typeList: [
                        { freetype_code: '%', freetype: '所有媒体' },
                        { freetype_code: '0', freetype: '视频课程' },
                        { freetype_code: '1', freetype: '图文课程' },
                        { freetype_code: '2', freetype: '音频课程' },
                    ]
                },
                {
                    name: 'timetype', title:'时间', show: false, select: 0,
                    typeList: [
                        { time_start: '%', timetype: '所有时间'},
                        { time_start: dateUtils.anyYearToday(-3), timetype: '近三年内' },
                        { time_start: dateUtils.anyYearToday(-1), timetype: '近一年内' },
                        { time_start: dateUtils.getLast3MonthToday(), timetype: '近三个月' },
                        { time_start: dateUtils.getLastMonthToday(), timetype: '近一个月' },
                    ]
                },
            ],
            microLessonList: [],
            pagination: {
                page: 1,
                limit: 16,
                end: false
            },
            hide: null
        }
    },
    mounted() {
        this.hide = this.$message.loading('正在加载中...', 0)
        this.getCatalogData()
        this.getTypeFilter(() => {
            this.queryRecommendLessons();
            this.queryMicroLessons();
        })
    },
    computed: {
        ...mapGetters([
            'mobilephone',
        ])
    },
    methods: {

        getCatalogData(){
            const that = this

            var params = {
                query_code:'LNH50P',
                page:1,
                limit:1
            }
            doQuery(params).then(res => {
                if (res.errcode == that.$consts.biz.OK) {
                    that.catalog_data = res.data
                    that.catalog = util.getTreeData(res.data,null,'folder-open')
                } else {
                    that.$message.error(res.errmsg)
                }
            }).finally(() => {
            })
        },

        onSelectRecommend(index) {
            const that = this
            that.$router.push({path: '/student/singleLesson?lessonId=' + that.recommend_lessons[index].lesson_id});
        },

        queryRecommendLessons() {
            const that = this
            doQuery({
                query_code: 'LNL00'
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK) {
                    that.recommend_lessons = res.data
                }
            }).finally(() => {
                setTimeout(that.hide(), 1000)
            })
        },

        getTypeFilter(func) {
            const that = this
            if (that.typeFilter[0].typeList.length == 0) {
                doQuery({
                    query_code: 'LN7C',
                    page: 1,
                    limit: 10
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK && res.data) {
                        var filter = that.typeFilter
                        filter[0].typeList = res.data
                        filter[0].show = true
                        filter[0].select = 0
                        that.typeShowIndex = 0
                        that.typeFilter = filter
                        if(func) {
                            func()
                        }
                    }else {
                        that.$message.error('查询课程类型异常')
                    }
                })
            }else {
                if(func) {
                    func()
                }
            }
        },
        /**
         * 获取筛选条件
         * bo_refresh:是否是刷新，默认为true
         */
        getFilterParam() {
            const that = this
            var filter = that.typeFilter
            var kdbtypeCode = filter[0].typeList[filter[0].select].kdbtype_code
            var freetypeCode = filter[1].typeList[filter[1].select].freetype_code
            var timeStart = filter[2].typeList[filter[2].select].time_start
            return {
                kdbtypeCode: kdbtypeCode,
                freetypeCode: freetypeCode,
                timeStart: timeStart,
                searchText: that.searchText
            }
        },

        queryMicroLessons(page = this.pagination.page, pageSize = this.pagination.limit) {
            const that = this
            const { kdbtypeCode, freetypeCode,timeStart,searchText } = that.getFilterParam()
            doQuery({
                query_code: 'LN7DN',
                param_str1: that.catalog_selected,
                param_str2: that.mobilephone,
                param_str3: freetypeCode,
                param_str4: timeStart,
                param_str5: searchText,
                page: page,
                limit: pageSize
            }).then(res => {
                if(res.errcode === that.$consts.biz.OK && res.data) {
                    res.data.forEach((item, index) => {
                        var lastLaunchTime = localStorage.getItem(that.$consts.storageKey.LAST_LAUNCH_TIME_.concat(that.mobilephone))
                        if(lastLaunchTime && item.create_time > lastLaunchTime) {
                            item.newtag = true
                        }else {
                            item.newtag = false
                        }

                        if(item.create_time) {
                            item.create_time = item.create_time.substring(0, 10)
                        }

                        that.getPlayPercent(item)
                    })
                    that.microLessonList = res.data
                    that.pagination.page = page
                    that.pagination.limit = pageSize
                    that.pagination.end = res.data.length < pageSize ? true : false
                }
            })
        },
        getPlayPercent(item) {
            const that = this
            var beginTime = localStorage.getItem(that.$consts.storageKey.XST_LESSON_PROGRESS_.concat(that.mobilephone).concat('_').concat(item.id))
            //如果存在观看进度
            if (beginTime && beginTime > 0 && item.time_length > 0 && item.time_length < 99999){
                item.time_percent = (beginTime * 100 / item.time_length).toFixed(1)
                if(item.time_percent > 99) {
                    item.time_percent = 100
                }
            }
            //时长
            if (item.time_length > 0 && item.time_length < 99999) {
                item.time_length_format = that.$cmyUtils.formatMinutes(item.time_length)
            }
        },

        onKdbTypeMenuClick({key}) {
            const that = this
            const { typeFilter } = that
            typeFilter[0].select = key
            that.$set(this.typeFilter,0,typeFilter[0])
            that.queryMicroLessons(1,that.pagination.limit)
        },

        onFreeTypeMenuClick({key}) {
            const that = this
            const { typeFilter } = that
            typeFilter[1].select = key
            that.$set(this.typeFilter,1,typeFilter[1])
            that.queryMicroLessons(1,that.pagination.limit)
        },

        onTimeTypeMenuClick({key}) {
            const that = this
            const { typeFilter } = that
            typeFilter[2].select = key
            that.$set(this.typeFilter,2,typeFilter[2])
            that.queryMicroLessons(1,that.pagination.limit)
        },
        toLessonDetail(lessonId) {
            const that = this
            that.$router.push({path: '/student/singleLesson?lessonId=' + lessonId})
        }
    }
}
